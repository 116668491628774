<template>
  <base-section id="showcase">
    <base-section-heading title="Tentifly assistant"> </base-section-heading>

    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="6"
          sm="6"
          xs="6"
          style="padding: 36px"
        >
          <v-row :justify="screenWidth < 600 ? 'center' : 'end'">
            <div class="talk-bubble tri-right round left-in showcaseChatBubble">
              <div class="talktext">
                <p>New order in</p>
              </div>
            </div>
          </v-row>
          <v-row :justify="screenWidth < 600 ? 'center' : 'end'">
            <div class="talk-bubble tri-right round left-in showcaseChatBubble">
              <div class="talktext">
                <p>New order in</p>
              </div>
            </div>
          </v-row>
          <v-row :justify="screenWidth < 600 ? 'center' : 'end'">
            <div class="talk-bubble tri-right round left-in showcaseChatBubble">
              <div class="talktext">
                <p>New order in</p>
              </div>
            </div>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="6"
          sm="6"
          xs="6"
          style="margin-bottom: 36px; padding: 16px; display: none;"
        >
          <div
            class="
              d-flex
              flex-column flex-1
              align-items-center
              justify-content-center
            "
          >
            <div
              :style="screenWidth < 600 ? '' : 'margin-right: auto;'"
              class="
                watch-container
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <div class="watch">
                <div
                  class="
                    pointers
                    h-100
                    w-100
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <div class="watch-name">CLIENT APP</div>
                </div>

                <div
                  class="
                    numbers
                    h-100
                    d-flex
                    flex-column
                    justify-content-between
                  "
                >
                  <div class="top d-flex justify-content-center"></div>

                  <div class="middle d-flex justify-content-between"></div>

                  <div class="bottom d-flex justify-content-center"></div>
                </div>
              </div>

              <div class="belt"></div>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="6"
          sm="6"
          xs="6"
          style="padding: 16px"
        >
          <figure
            style="margin-left: auto; margin-right: auto"
            :style="screenWidth < 600 ? '' : 'margin-right: unset;'"
            class="iphone"
          >
            ADMIN APP
          </figure>
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="6"
          sm="6"
          xs="6"
          style="margin-bottom: 36px; padding: 36px"
        >
          <v-row :justify="screenWidth < 600 ? 'center' : 'start'">
            <div class="talk-bubble tri-right round left-in showcaseChatBubble">
              <div class="talktext">
                <p>New order in</p>
              </div>
            </div>
          </v-row>
          <v-row :justify="screenWidth < 600 ? 'center' : 'start'">
            <div class="talk-bubble tri-right round left-in showcaseChatBubble">
              <div class="talktext">
                <p>New order in</p>
              </div>
            </div>
          </v-row>
          <v-row :justify="screenWidth < 600 ? 'center' : 'start'">
            <div class="talk-bubble tri-right round left-in showcaseChatBubble">
              <div class="talktext">
                <p>New order in</p>
              </div>
            </div>
          </v-row>
          <v-row :justify="screenWidth < 600 ? 'center' : 'start'">
            <div class="talk-bubble tri-right round left-in showcaseChatBubble">
              <div class="talktext">
                <p>New order in</p>
              </div>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<style>
.showcaseChatBubble {
  position: relative;
  margin-bottom: 24px;
  -webkit-box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.talk-bubble {
  display: inline-block;
  height: auto;
  background-color: white;
  margin-right: 8px;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  -webkit-text-size-adjust: 100%;
  word-break: normal;
  tab-size: 4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "PT Sans", sans-serif;
  -webkit-box-direction: normal;
  pointer-events: auto;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  text-align: initial;
  background-repeat: no-repeat;
  box-sizing: inherit;
  margin: 0;
  color: black;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}
</style>

<style lang="scss" scoped>
.watch-container .watch .pointers .watch-name {
  position: absolute;
  top: 5rem;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.watch-container {
  position: relative;
  width: 200px;
}

.watch-container::after {
  content: "";
  position: absolute;
  height: 200px;
  width: 200px;
  background-color: #333333;
  -webkit-box-shadow: 0 0 60px 40px rgba(80, 80, 80, 0.1);
  -moz-box-shadow: 0 0 60px 40px rgba(80, 80, 80, 0.1);
  box-shadow: 0 0 60px 40px rgba(80, 80, 80, 0.1);
  border-radius: 50%;
  z-index: -1;
}

.watch-container .watch {
  height: 200px;
  width: 200px;
  border: 6px solid #111;
  background-color: #333333;
  color: #fff;
  padding: 6px;
  border-radius: 50%;
  letter-spacing: -0.022em;
  font-size: 20px;
  position: absolute;
  text-shadow: 0 0 0.5em #000;
}

.watch-container .belt {
  width: 100px;
  height: 280px;
  border-radius: 10px;
  background-color: #212121;
  box-shadow: 0px 0px 20px #000000eb;
}
</style>

<style lang="scss" scoped>
.iphone {
  display: block;
  color: #fff;
  text-align: center;
  letter-spacing: -0.022em;
  font-size: 20px;
  text-shadow: 0 0 0.5em #000;
  background-color: #000;
  border: solid #111;
  position: relative;
  box-shadow: 0 0.5em 2em 0.2em rgba(0, 0, 0, 0.33), 0 0 0 0.5px #000 inset;
  transition: all 0.1s linear, line-height 0s linear;
  background-position: 50% 100%, center;
  transform-origin: bottom center;
  background: #333333;

}

.iphone {
  width: 200px;
  //0.4937325905292479
  height: 405px;
  line-height: 385px;
  border: 6px solid #111;
  border-radius: 25px;
}
</style>

<script>
export default {
  name: "SectionShowcase",
  computed: {
    screenWidth: {
      get() {
        return this.$store.state.screenWidth;
      },
      set(newValue) {
        return this.$store.dispatch("setScreenWidth", newValue);
      }
    }
  },
  data() {
    return {
      
    };
  },
};
</script>
